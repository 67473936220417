import './Tasks.css';
import { useState, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';
import {
	Table,
	Input,
	Modal,
	Form,
	Select,
	Button,
	message,
	Divider,
	Popconfirm,
	Tag,
	Typography,
	Descriptions,
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { createTask, deleteTask, getTasks, updateTask } from './utils/api';
import { get } from 'lodash';
import UserSelfContext from './context/SelfContext';
import AudioPlayer from './AudioPlayer';

const { Option } = Select;
const { Search } = Input;


function Tasks() {
	const [form] = Form.useForm();

	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [selectedTask, setSelectedTask]: any = useState({});
	const [modalVisible, setModalVisible]: any = useState(false);

	const { userSelf } = useContext(UserSelfContext);
	const organizationId = userSelf.activeOrganizationId;

	async function fetchTasks() {
		setLoading(true);
		try {
			const data = await getTasks('');
			setTasks(data);
		} catch (err) {
			message.error('Something went wrong, cannot get tasks...');
		}
		setLoading(false);
	}

	useEffect(() => {
		fetchTasks();
	}, []);

	useEffect(() => {
		form.setFieldsValue({
			callSign: selectedTask.callSign,
			description: selectedTask.description,
			status: selectedTask.status
		})
	}, [selectedTask]);

	useEffect(() => {
		// Initialize socket connection
		const socket = io({
			withCredentials: true,
		});

		socket.on(`${organizationId}-task`, (messageData) => {
			if (messageData.action === 'create') {
				const newTasks: any = [...tasks];
				newTasks.unshift(messageData.data);

				setTasks(newTasks)
			}

		});

		// Clean up the socket connection on component unmount
		return () => {
			socket.disconnect();
		};
	}, [tasks]);

	async function onSave(payload: any) {
		setSaving(true);
		if (selectedTask.id) {
			payload.id = selectedTask.id;
		}

		try {
			if (payload.id) {
				await updateTask(payload.id, payload);
			} else {
				await createTask(payload);
			}

			await fetchTasks();

			setSelectedTask({});
			setModalVisible(false);
			setSaving(false);
		} catch (err) {
			setSaving(false);
			message.error('Something went wrong, cannot save task...');
		}
	}

	async function onDelete(id: string) {
		try {
			await deleteTask(id);
			await fetchTasks();
		} catch (err) {
			message.error('Something went wrong, cannot delete task...');
		}
	}

	function getStatusTag(status: string) {
		switch (status) {
			case 'OPEN':
				return <Tag>OPEN</Tag>;
			case 'IN PROGRESS':
				return <Tag color="blue">IN PROGRESS</Tag>;
			case 'DONE':
				return <Tag color="green">DONE</Tag>;
			default:
				return <Tag>OPEN</Tag>;
		}
	}

	const columns = [
		{
			key: 'dateCreated',
			title: 'Date',
			dataIndex: 'dateCreated',
			width: '140px',
			sorter: (a: any, b: any) =>
				new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
			render: (timestamp: string) => new Date(timestamp).toLocaleDateString(),
		},
		{
			key: 'callSign',
			title: 'Call sign',
			dataIndex: "callSign",
			width: '140px',
			render: (text: any, record: any) => <span><UserOutlined /> <Typography.Text strong>{get(record.callSign, 'name') || 'Unknown'}</Typography.Text></span>
		},
		{
			key: 'status',
			title: 'Status',
			dataIndex: 'status',
			width: '180px',
			render: (text: string) => getStatusTag(text)
		},
		{
			key: 'description',
			title: 'Description',
			dataIndex: "description",
			sorter: (a: any, b: any) => a.description.length - b.description.length,
		},
		{
			title: 'Action',
			dataIndex: 'x',
			key: 'x',
			width: 200,
			render: (text: string, record: any) => <span>
				<Button
					size="small"
					type="link"
					icon={<EditOutlined />}
					onClick={() => {
						setSelectedTask(record);
						setModalVisible(true);
					}}
				>
					Edit
				</Button>
				<Divider type="vertical" ></Divider>
				<Popconfirm
					placement="topRight"
					title="Delete task?"
					description="Are you sure to delete this task?"
					onConfirm={() => onDelete(record.id)}
					okText="Yes"
					cancelText="No"
				>
					<Button
						icon={<DeleteOutlined />}
						size="small"
						type="link"
					>
						Delete
					</Button>
				</Popconfirm>
			</span>,
		}];

	const statusOptions = [
		{ value: 'OPEN', label: getStatusTag('OPEN') },
		{ value: 'IN PROGRESS', label: getStatusTag('IN PROGRESS') },
		{ value: 'DONE', label: getStatusTag('DONE') },
	];

	const handleSubmit = (values: any) => {
		onSave(values);
	};

	const onSearch = (value: any) => {

	};


	return (
	<div className="tasksContainer">
		<div className="actions">
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={() => setModalVisible(true)}>
					Task
			</Button>
			<Search
				onChange={onSearch}
				placeholder="Search"
				className="search"
				allowClear
				style={{ width: 300 }}
			/>
		</div>

		<Table
			pagination={{ pageSize: 20 }}
			columns={columns}
			loading={loading}
			rowKey="id"
			scroll={{y: 'calc(100vh - 264px)'}}
			dataSource={tasks}
			expandable={{
				expandedRowRender: (record) =>
				<div className="expandedWrapper">
					<Typography.Text style={{marginRight: 8 }} strong>Message:</Typography.Text>
					<Typography.Text italic>{record.message.text || 'No message transcrip...'}</Typography.Text>
					<AudioPlayer source={`/uploads/${record.message.recording}`} />
				</div>,
			}}
		/>

		{/* ADD TASK */}

		<Modal
			open={modalVisible}
			afterClose={() => {
				setSelectedTask({})
			}}
			onCancel={() => {
				setSelectedTask({});
				setModalVisible(false);
			}}
			title={selectedTask.id ? 'Edit task' : 'Add task'}
			footer={[
				<Button
					key="back"
					onClick={() => {
						setModalVisible(false);
						setSelectedTask({})
					}}
				>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					loading={saving}
					onClick={() => form.submit()}
				>
					Save
				</Button>
			]}
		>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Form.Item
				label="Call sign"
				name="callSign"
				>
				<Input placeholder="Enter the title" />
				</Form.Item>
				<Form.Item
				label="Description"
				name="description"
				>
				<Input.TextArea placeholder="Enter the description" />
				</Form.Item>
				<Form.Item
				name="status"
				label="Status"
				initialValue="OPEN"
				rules={[{ required: true, message: "Please choose the status" }]}
				>
				<Select placeholder="Select status">
					{statusOptions.map((status) => (
					<Option key={status.value} value={status.value}>
						{status.label}
					</Option>
					))}
				</Select>
				</Form.Item>
			</Form>
		</Modal>
	</div>);
}

export default Tasks;

