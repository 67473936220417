import React, { useContext, useState } from 'react';
import { Avatar, Layout, Menu, message } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import Messages from './Messages';
import Configuration from './Configuration';
import Tasks from './Tasks';
import Recorder from './Recorder';
import UserSelfContext from './context/SelfContext';
import { logout } from './utils/api';

const { Header, Content } = Layout;

function Main() {
	const [activeMenu, setActiveMenu] = useState('messages');

	const { userSelf } = useContext(UserSelfContext);

	function getActiveContent() {
		switch (activeMenu) {
			case 'configuration':
				return <Configuration />;
			case 'tasks':
				return <Tasks />;
			default:
				return <Messages />;
		}
	}

	async function onLogout() {
		try {
			await logout();
			window.location.href = '/login';
		} catch (err) {
			message.error('Cannot log out.');
		}
	}

	return (
		<Layout>
			<Header style={{ display: 'flex', alignItems: 'center', position: 'fixed', width: '100%', zIndex: 9 }}>
				<img style={{ height: 42, marginTop: -6, marginRight: 24 }} alt="logo" src="logo-light.svg" />	
				<Menu
					selectedKeys={[activeMenu]}
					onSelect={({key}) => setActiveMenu(key)}
					theme="dark"
					mode="horizontal" 
					style={{ width: '50%' }}
					items={[{
						key: 'messages',
						label: 'Messages'
					},{
						key: 'tasks',
						label: 'Tasks'
					}, {
						key: 'configuration',
						label: 'Configuration'
					}]}
				/>
				<div style={{ marginLeft: 'auto', display: 'flex', height: 64 }}>
					<Recorder/>
					<Menu
						style={{ width: 214 }}
						theme="dark"
						selectedKeys={[]}
						mode="horizontal"
					>
						<Menu.SubMenu
							key="right_menu"
							title={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{<Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />}
									<div style={{ width: 140, overflow: 'hidden', textOverflow: 'ellipsis' }}>{get(userSelf, 'email')}</div>
								</div>
							}
						>
							<Menu.Item
								onClick={() => {
									onLogout();
								}}
								key="log_out"
								icon={<LogoutOutlined />}
							>
								Log out
							</Menu.Item>
						</Menu.SubMenu>
					</Menu>
				</div>
			</Header>
			<Content style={{ marginTop: 64, padding: '24px', height: 'calc(100vh - 64px)' }}>
				{getActiveContent()}
			</Content>
		</Layout>
	);
}

export default Main;
