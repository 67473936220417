import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { login } from './utils/api';

import './Login.css'

const Login = () => {
	const onFinish = async (values: any) => {
		try {
			await login(values);
			window.location.href = '/';
		} catch (err) {
			message.error('Wrong email or password!');
		}
	};

	return (
		<div className="loginWrapper">
			<img style={{ height: 48, marginBottom: 24 }} alt="logo" src="logo-dark.svg" />	
			<Form name="login" layout="vertical" onFinish={onFinish}>
				<Form.Item label="Email" name="email" rules={[{ required: true, message: 'Palun sisestage email!' }]}>
					<Input />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Please enter password!' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item>
					<Button block type="primary" htmlType="submit">
						Login
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Login;
