import axios from 'axios';

export const API_URL = 'http://localhost:3030'

export const login = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/auth/login`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const logout = async () => {
	const { data } = await axios({
		method: 'get',
		url: `/auth/logout`,
		withCredentials: true
	});

	return data;
};

export const register = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/register`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const getUserSelf = async () => {
	const { data } = await axios.get(`/api/users/self`, {
		withCredentials: true,
	});
	return data;
};

export const getMessages = async (query: string) => {
	const { data } = await axios.get(`/api/messages/${query}`, {
		withCredentials: true,
	});
	return data;
};

// ------------- CALL SIGNS ---------------

export const getCallSigns = async (query: string = '') => {
	const { data } = await axios.get(`/api/callSigns${query}`, {
		withCredentials: true,
	});
	return data;
};

export const getCallSign = async (id: string) => {
	const { data } = await axios.get(`/api/callSigns/${id}`, {
		withCredentials: true,
	});
	return data;
};

export const deleteCallSign = async (id: string) => {
	const { data } = await axios.delete(`/api/callSigns/${id}`, {
		withCredentials: true,
	});
	return data;
};

export const updateCallSign = async (id: string, payload: any) => {
	if (!id) {
		return null;
	}

	const { data } = await axios({
		method: 'put',
		url: `/api/callSigns/${id}`,
		data: payload,
		withCredentials: true,
	});

	return data;
};

export const createCallSign = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/callSigns`,
		data: payload,
		withCredentials: true,
	});

	return data;
};

// ------------- ACTIONS ---------------

export const getActions = async (query: string = '') => {
	const { data } = await axios.get(`/api/actions${query}`, {
		withCredentials: true,
	});
	return data;
};

export const getAction = async (id: string) => {
	const { data } = await axios.get(`/api/actions/${id}`);
	return data;
};

export const deleteAction = async (id: string) => {
	const { data } = await axios.delete(`/api/actions/${id}`, {
		withCredentials: true,
	});
	return data;
};

export const updateAction = async (id: string, payload: any) => {
	if (!id) {
		return null;
	}

	const { data } = await axios({
		method: 'put',
		url: `/api/actions/${id}`,
		data: payload,
		withCredentials: true,
	});

	return data;
};

export const createAction = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/actions`,
		data: payload,
		withCredentials: true,
	});

	return data;
};

// ------------- TASKS ---------------

export const getTasks = async (query: string = '') => {
	const { data } = await axios.get(`/api/tasks${query}`, {
		withCredentials: true,
	});
	return data;
};

export const getTask = async (id: string) => {
	const { data } = await axios.get(`/api/tasks/${id}`, {
		withCredentials: true,
	});
	return data;
};

export const deleteTask = async (id: string) => {
	const { data } = await axios.delete(`/api/tasks/${id}`, {
		withCredentials: true,
	});
	return data;
};

export const updateTask = async (id: string, payload: any) => {
	if (!id) {
		return null;
	}

	const { data } = await axios({
		method: 'put',
		url: `/api/tasks/${id}`,
		data: payload,
		withCredentials: true,
	});

	return data;
};

export const createTask = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/tasks`,
		data: payload,
		withCredentials: true,
	});

	return data;
};

