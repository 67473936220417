import React, { useContext, useEffect, useState } from 'react';
import { Button, Empty, message, Tag, theme, Timeline, Typography } from 'antd';
import get from 'lodash/get';
import { io } from 'socket.io-client';
import { LoadingOutlined, UserOutlined, FlagOutlined, ReloadOutlined } from '@ant-design/icons';
import { getMessages } from './utils/api';
import dayjs from 'dayjs';
import AudioPlayer from './AudioPlayer';

import './Messages.css';
import UserSelfContext from './context/SelfContext';

function Messages() {
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	const { userSelf } = useContext(UserSelfContext);
	const organizationId = userSelf.activeOrganizationId;

	const [loading, setLoading] = useState(false);
	const [messages, setMessages]: any[] = useState([]);
	const [hasMore, setHasMore]: any = useState(true);
	const [page, setPage]: any = useState(1);


	async function fetchMessages() {
		setLoading(true);
		try {
			const data = await getMessages(`?page=${page}`);

			if (data.length < 50) {
				setHasMore(false)
			}

			setMessages([...messages, ...data]);
		} catch (err) {
			message.error('Something went wrong, cannot get messages...');
		}
		setLoading(false);
	}

	useEffect(() => {
		// Initialize socket connection
		const socket = io({
			withCredentials: true,
		});
	
		// Listen for a custom event 'message' from the server
		socket.on(`${organizationId}-message`, (message: any) => {
			if (message.action === 'create') {
				const newMessages = [...messages];
				newMessages.unshift(message.data);

				setMessages(newMessages)
			}


			if (message.action === 'update') {
				const newMessages = messages.map((m: any) => {
					if (m.id === message.data.id) {
						return {
							...m,
							...message.data
						};
					}

					return m;
				});

				setMessages(newMessages);
			}
		});
	
		// Clean up the socket connection on component unmount
		return () => {
			socket.disconnect();
		};
	}, [messages]);

	useEffect(() => {
		fetchMessages();
	}, [page])

	function getTimelineItems() {
		return messages.map((message: any) => ({
			children: <div>
				<div>
					<Typography.Text style={{marginRight: 8 }} type="secondary">{dayjs(message.dateCreated).format('DD.MM.YYYY HH:mm:ss')}</Typography.Text>
					<span style={{marginRight: 8 }}><UserOutlined /> <Typography.Text strong>{get(message.callSign, 'name') || 'Unknown'}</Typography.Text></span>
					{get(message.action, 'id') && <span><FlagOutlined /> <Typography.Text strong>{get(message.action, 'title') || 'Unknown action'}</Typography.Text></span>}
				</div>
				<div className="messagesText">
					<Typography.Text italic>{message.text || 'Processing message...'}</Typography.Text>
					<AudioPlayer source={`/uploads/${message.recording}`} />
				</div>
				{get(message.task, 'id') &&
					<div style={{ marginTop: 8 }}>
						<Tag>{message.task.description}</Tag>
					</div>
				}
			</div>
		}))
	}

	return (
		<div className="messagesWrapper" style={{background: colorBgContainer}}>
			{messages.length ? <Timeline
				pending={hasMore && <Button type="link" size="small" onClick={() => setPage(page + 1)} loading={loading}>Load more...</Button>}
				pendingDot={loading ? <LoadingOutlined /> : <ReloadOutlined />}
				items={[
					...getTimelineItems()
				]}
			/> : <Empty description="No messages. Start recording to add new messages." />}
			
		</div>
	);
}

export default Messages;
