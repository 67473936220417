import React, { useEffect } from 'react';
import { theme } from 'antd';

import './configuration.css';
import CallSigns from './CallSigns';

function Configuration() {
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	useEffect(() => {
	}, [])

	return (
		<div className="configurationWrapper" style={{background: colorBgContainer}}>
			<CallSigns />
		</div>
	);
}

export default Configuration;
