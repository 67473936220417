import React, { useEffect, useRef, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { SoundOutlined, PauseOutlined } from '@ant-design/icons'

type PlayerProps = {
	source: string;
};

function AudioPlayer(props: PlayerProps) {
	const { source } = props;

	const audioRef: any = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
  
	const handlePlayPause = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
				setIsPlaying(false);
			} else {
				audioRef.current.src = source;
				audioRef.current.play();
				setIsPlaying(true);
			}
		}
	};

	return (<span>
		<audio onEnded={() => setIsPlaying(false)} ref={audioRef}>
		  <source src={source} type="audio/wav" />
		  Your browser does not support the audio element.
		</audio>

		<Tooltip title="Play recording">
			<Button type="link" size="small" onClick={handlePlayPause}>
		  		{isPlaying ? <PauseOutlined /> : <SoundOutlined />}
			</Button>
		</Tooltip>
	  </span>
	  )
  
  };
  
  export default AudioPlayer;