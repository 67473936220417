import React from 'react';


import './SpeakingAnimation.css';

function SpeakingAnimation() {
	return <div id="bars">
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
		<div className="bar"></div>
	</div>
};
  
export default SpeakingAnimation;