import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { register } from './utils/api';

import './Register.css'

const Register = () => {
	const [loading, setloading] = useState(false);

	const onFinish = async (values: any) => {
		setloading(true);
		try {
			await register(values);
			window.location.href = '/login';
		} catch (err) {
			message.error('Something went wrong, cannot register organization.');
		}
		setloading(false);
	};

	return (
		<div className="registerWrapper">
			<img style={{ height: 48, marginBottom: 24 }} alt="logo" src="logo-dark.svg" />	
			<Form name="login" layout="vertical" onFinish={onFinish}>
				<Form.Item label="Organization name" name="organizationName" rules={[{ required: true, message: 'Palease enter organization name' }]}>
					<Input />
				</Form.Item>
				<Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter email!' }]}>
					<Input />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Please enter password!' }]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item
					label="Register key"
					name="registerKey"
					rules={[{ required: true, message: 'Please enter register key!' }]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item>
					<Button block loading={loading} type="primary" htmlType="submit">
						Register
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Register;
