import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import SpeakingAnimation from "./SpeakingAnimation";
import UserSelfContext from "./context/SelfContext";

type Props = {
	listening: boolean
};

const AudioResponse = ({ listening }: Props) => {
	const audioRef: any = useRef(null);
	const [source, setSource] = useState('intro.mp3');
	const [playing, setPlaying] = useState(false);

	const { userSelf } = useContext(UserSelfContext);
	const organizationId = userSelf.activeOrganizationId;

	// Event handlers for audio playback
	const handlePlay = () => {
		setPlaying(true); // Set the state to true when audio plays
	};
	
	const handlePause = () => {
		setPlaying(false); // Set the state to false when audio is paused
	};

	useEffect(() => {
		// Auto play the audio when `source` changes
		if (audioRef.current && source && listening) {
			audioRef.current.src = source;
			audioRef.current.load();  // Reload the new source
			audioRef.current.play();  // Play the new audio
		}
		}, [source]);  // This useEffect runs whenever `source` changes

		useEffect(() => {
		// Initialize socket connection
		const socket = io({
			withCredentials: true,
		});

		console.log(`${organizationId}-responseMessage`);

		socket.on(`${organizationId}-responseMessage`, (responseData) => {
			if (responseData.audio) {
				console.log(responseData.audio);
				setSource(`/${responseData.audio}`);
			}

		});

		// Clean up the socket connection on component unmount
		return () => {
			socket.disconnect();
		};
	}, [organizationId]);

	useEffect(() => {
		// Auto play the audio when the component mounts
		if (audioRef.current && listening) {
			audioRef.current.src = 'intro.mp3';
			audioRef.current.play();
		}
		if (!listening && audioRef.current) {
			audioRef.current.pause();
		}
	}, [listening]);

	return (<Fragment>
		<audio
			id="audioPlayer"
			style={{display: 'none'}}
			ref={audioRef}
			autoPlay
			onPlay={handlePlay}   // Add onPlay event handler
			onPause={handlePause} // Add onPause event handler
		>
			<source src={source} type="audio/mp3" />
			Your browser does not support the audio element.
		</audio>
		{playing && <SpeakingAnimation />}
	</Fragment>
	);
};

export default AudioResponse;