import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Main from './Main';
import { getUserSelf } from './utils/api';
import UserSelfContext from './context/SelfContext';
import { Skeleton } from 'antd';
import Register from './Register';


function App() {
	const [loading, setloading] = useState(true);
	const [userSelf, setUserSelf] = useState(false);
	const path = window.location.pathname;

	async function fetchUserSelf() {
		setloading(true);
		try {
			const userSelfData = await getUserSelf();
			setUserSelf(userSelfData)

			if (path === '/login') {
				window.location.href = '/'
			}
			setloading(false);
		} catch (error: any) {
			if (error.status === 403 && path !== '/login') {
				window.location.href = "/login";
			}
		}
	}

	useEffect(() => {
		if (path === '/register') {
			return;
		}
		fetchUserSelf();
	}, []);

	return (
		<Router>
			<Routes>
				<Route path="/register" element={<Register />} />
				<Route path="/login" element={<Login />} />
				<Route 
					path="*" 
					element={
						<UserSelfContext.Provider value={{ userSelf, fetchUserSelf }}>
							{loading ? <Skeleton style={{ padding: 24 }} active /> : <Main />}
						</UserSelfContext.Provider>
					} />
			</Routes>
		</Router>
	);
}

export default App;
