import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import { PauseOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useMicVAD, utils } from "@ricky0123/vad-react";
import axios from 'axios';
import AudioResponse from './AudioResponse';

// Function to upload the Blob to your API endpoint
const uploadAudio = async (formData: any) => {
	try {
	  await axios.post(`/api/messages`, formData, {
		headers: {
		  'Content-Type': 'multipart/form-data',
		},
		withCredentials: true
	  });
	} catch (error) {
	  console.error('Error uploading audio:', error);
	}
};


function Recorder() {
	const vad = useMicVAD({
		startOnLoad: false,
		redemptionFrames: 25,
		onVADMisfire: () => {
			message.destroy();
		},
		onSpeechStart: () => {
			message.open({
				type: 'loading',
				content: 'Receiving...',
				duration: 0,
			});
		},
		onSpeechEnd: (audio) => {
			const wavBuffer = utils.encodeWAV(audio);
			const audioBlob = new Blob([wavBuffer], { type: 'audio/wav' });
			
			const formData = new FormData();
			formData.append('file', audioBlob, 'audio.wav');
	  
			uploadAudio(formData);
			message.destroy()
		}
	});

	useEffect(() => {
		if (!vad.listening) {
			message.destroy();
		}
	}, [vad.listening])
	
	return (<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
		<AudioResponse listening={vad.listening} />
		<Button
			type="primary"
			icon={vad.listening ? <PauseOutlined /> : <CaretRightOutlined />}
			style={{ marginLeft: 24 }}
			loading={vad.loading}
			onClick={() => vad.toggle()}>
				{vad.listening ? 'Stop listening' : 'Start listening'}
		</Button>
	</div>
	);
}

export default Recorder;
