import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Input, message, Modal, Popconfirm, Table, Typography } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { createCallSign, deleteCallSign, getCallSigns, updateCallSign } from './utils/api';

function CallSigns() {
	const [callSigns,  setCallSigns] = useState([]);
	const [saving,  setSaving] = useState(false);
	const [loading,  setLoading] = useState(false);
	const [activeCallSign, setActiveCallSign]: any = useState({});
	const [modalOpen,  toggleModal] = useState(false);

	const [form] = Form.useForm();

	async function fetchCallSigns() {
		setLoading(true);
		try {
			const data = await getCallSigns('');
			setCallSigns(data);
		} catch (err) {
			message.error('Something went wrong, cannot get call signs...');
		}
		setLoading(false);
	}

	useEffect(() => {
		fetchCallSigns();
	}, []);

	useEffect(() => {
		form.setFieldsValue({
			name: activeCallSign.name,
			referenceKey: activeCallSign.referenceKey,
			info: activeCallSign.info
		});
	}, [activeCallSign])

	async function onSave(payload: any) {
		setSaving(true);

		payload.id = activeCallSign.id;

		try {
			if (payload.id) {
				await updateCallSign(payload.id, payload);
			} else {
				await createCallSign(payload);
			}

			await fetchCallSigns();

			setActiveCallSign({});
			toggleModal(false);
			setSaving(false);
		} catch (err) {
			setSaving(false);
			message.error('Something went wrong, cannot save call sign...');
		}
	}

	async function onDelete(id: string) {
		try {
			await deleteCallSign(id);
			await fetchCallSigns();
		} catch (err) {
			message.error('Something went wrong, cannot delete call signs...');
		}
	}
	
	return (<div>
		<Button
			type="primary"
			icon={<PlusOutlined />}
			style={{ marginBottom: 24 }}
			onClick={() => toggleModal(true)}>
				Call sign
		</Button>
		<Table
			dataSource={callSigns}
			locale={{
				emptyText: 'No call signs added'
			}}
			loading={loading}
			rowKey="id"
			columns={[
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name',
					render: text => <Typography.Text strong>{text}</Typography.Text>
				},
				{
					title: 'Reference key',
					dataIndex: 'referenceKey',
					key: 'referenceKey',
				},
				{
					title: 'Info',
					dataIndex: 'info',
					key: 'info',
				},
				{
					title: 'Action',
					dataIndex: 'x',
					key: 'x',
					width: 200,
					render: (text, record: any) => <span>
						<Button
							size="small"
							type="link"
							icon={<EditOutlined />}
							onClick={() => {
								setActiveCallSign(record);
								toggleModal(true);
							}}
						>
							Edit
						</Button>
						<Divider type="vertical" ></Divider>
						<Popconfirm
							placement="topRight"
							title="Delete call sign?"
							description="Are you sure to delete this call sign?"
							onConfirm={() => onDelete(record.id)}
							okText="Yes"
							cancelText="No"
						>
							<Button
								icon={<DeleteOutlined />}
								size="small"
								type="link"
							>
								Delete
							</Button>
						</Popconfirm>
					</span>,
				},
			]}
		/>
		<Modal
			open={modalOpen}
			title={activeCallSign.id ? 'Edit call sign' : 'Add call sign'}
			onOk={onSave}
			destroyOnClose
			onCancel={() => {
				toggleModal(false);
				setActiveCallSign({});
			}}
			footer={[
				<Button
					key="back"
					onClick={() => {
						toggleModal(false);
						setActiveCallSign({})
					}}
				>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					loading={saving}
					onClick={() => form.submit()}
				>
					Save
				</Button>
			]}
		>
			<Form
				form={form}
				name="callSign"
				layout="vertical"
				onFinish={onSave}
			>
				<Form.Item
					name="name"
					label="Name"
					initialValue={activeCallSign.name}
					rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item
					name="referenceKey"
					label="Reference key"
					initialValue={activeCallSign.referenceKey}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="info"
					label="Info"
					initialValue={activeCallSign.info}
				>
					<Input.TextArea />
				</Form.Item>
			</Form>
		</Modal>
	</div>
	);
}

export default CallSigns;
